import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { EventDto, ReservationSlotDto } from '../../dtos/event/event.dto';
import { HelperService } from '../../services/helper.service';
import { CONSTANTS } from './../../../../../constants';

@Component({
  selector: 'app-public-calendar',
  templateUrl: './public-calendar.component.html',
  styleUrl: './public-calendar.component.scss'
})

export class PublicCalendarComponent implements OnInit, OnChanges {

	@Input("mode") mode: 'PUBLIC' | 'PRIVATE';
	@Input("event") mainEvent: EventDto;
	@Output('select') select = new EventEmitter();

	readonly CONSTANTS = CONSTANTS;

	slots: ReservationSlotDto[] = [];
	viewDate = new Date();
	events: CalendarEvent[] = [];
	refresh = new Subject();
	dayStartHour = CONSTANTS.CALENDAR_START_HOUR;
	dayEndHour = CONSTANTS.CALENDAR_END_HOUR;
	hourSegments = 5;

	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService
	) {}

	ngOnInit(): void {
		this.mapEvents();
		this.viewDate = moment(this.mainEvent.date, CONSTANTS.DATE_HOUR_TRANSACTION_FORMAT).toDate();
		this.dayStartHour = this.events[0].start.getHours();
		this.dayEndHour = this.events[this.events.length-1].end.getHours()-1;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.mapEvents();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	eventTimesChanged(evt) {

	}

	setPeriod(evt) {

	}

	eventClicked(evt) {
		if (this.mode === 'PUBLIC') {
			this.selectForReservation(evt.event)
		} else {
			this.allowReservationRemoval(evt.event)
		}
	}

	private mapEvents(): void {
		this.events = this.mainEvent.reservationSlotDtos
			.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
			.map(slot => this.getCalendarEvent(slot));
	}

	private selectForReservation(calendarEvent: CalendarEvent) {
		this.events.forEach((e: CalendarEvent) => {
			const rs = this.mainEvent.reservationSlotDtos.find(rs => rs.id === e.id)

			if (rs.booked) {
				return;
			}

			e.color.primary = "blue";
			e.color.secondary = "#FFF";
			e.color.secondaryText = "#1e90ff";

			if (rs.id === calendarEvent.id) {
				calendarEvent.color.primary = "#D49A56";
				calendarEvent.color.secondary = "#D49A56";
				calendarEvent.color.secondaryText = "#FFF"
				this.select.emit(rs);
			}
		})
	}

	private allowReservationRemoval(calendarEvent: CalendarEvent) {
		const rs = this.mainEvent.reservationSlotDtos.find(rs => rs.id === calendarEvent.id)
		if (rs.booked) {
			this.select.emit(rs)
		}
	}

	private getCalendarEvent(slot: ReservationSlotDto): CalendarEvent {
		return {
			id: slot.id,
			start: moment(slot.startDate, CONSTANTS.DATE_HOUR_TRANSACTION_FORMAT).toDate(),
			end: moment(slot.endDate, CONSTANTS.DATE_HOUR_TRANSACTION_FORMAT).toDate(),
			title: slot.booked
				? this.mode === 'PRIVATE'
					? 'Réservé par ' + slot.guestName
					: 'Réservé.'
				: "Libre",
			color: {
				primary: slot.booked ? '#ccc' : 'blue',
				secondary:  slot.booked ? '#ccc' : '#FFF'
			},
			draggable: false,
			resizable: {
				beforeStart: false,
				afterEnd: false
			}
		}
	}
}
