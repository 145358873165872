<div id="modal-container" *ngIf="form">

	<h1 *ngIf="!invoice">Nouvelle facture</h1>
	<h1 *ngIf="invoice">#{{invoice.reference}}</h1>

	<form [formGroup]="form" (submit)="onSubmit()">


		<h3>Sélectionnez les prestations incluses dans la facture</h3>
		<button class="button is-primary is-inverted" type="button" (click)="toggleAll()" [disabled]="">Sélectionner toutes les prestations non facturées</button>

		<div class="line" *ngFor="let option of eventOptions">
			<mat-checkbox
				[checked]="form.get('proposalLineIds').value.includes(option.id) || option.invoiced"
				[disabled]="!!option.invoiceRef && option.invoiceRef !== invoice?.reference"
				(change)="toggleOptionId(option.id)"
			></mat-checkbox>
			<app-tile
				[animate]="false"
				[imageUrl]="option.event.activity.image"
				[title]="option.event.activity.title"
				[smallText]="true"
				aspectRatio="8/5">
			</app-tile>
			<div class="details">
				<div>{{ helper.dateFr(option.event.date) }} ({{ helper.hourFr(option.event.date) }})</div>
				<div>{{ option.event.offer.sessionConditions }}</div>
			</div>
			<div class="status" *ngIf="option.invoiced">
				<img src="../../../../../../../assets/images/checkmark.png" />
				<p>Incluse dans {{option.invoiceRef}}</p>
			</div>
		</div>

		<div class="columns">
			<div class="column is-3">
				<mat-form-field>
					<mat-label>Référence du client</mat-label>
					<input matInput formControlName="clientReference">
				</mat-form-field>
			</div>
			<div class="column is-3">
				<mat-form-field>
					<mat-label>Mode de paiement</mat-label>
					<mat-select formControlName="paymentType" (selectionChange)="updateValidators()">
						<mat-option *ngFor="let option of PAYMENT_TYPES" [value]="option.value">{{option.label}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column is-3">
				<mat-form-field>
					<mat-label>Lien de paiement</mat-label>
					<input matInput
						formControlName="paymentLink"
						placeholder="https://stripe.com?invoice=xyz"
					>
				</mat-form-field>
			</div>
			<div class="column is-3">
				<mat-form-field>
					<mat-label>Etat</mat-label>
					<mat-select formControlName="status">
						<mat-option
							*ngFor="let s of INVOICE_STATUS_OBJ"
							[value]="s.value"
						>
							{{s.label}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>


		<div id="buttons">
			<button class="button" type="button" (click)="close()">
				<span>Fermer</span>
			</button>

			<button class="button is-danger is-inverted" type="button" (click)="deleteInvoice()" *ngIf="!!invoice">
				<span>Supprimer cette facture</span>
			</button>

			<button class="button is-primary" type="submit" [disabled]="!form.valid">
				<span *ngIf="!!invoice">Enregistrer</span>
				<span *ngIf="!invoice">Créer la facture</span>
			</button>
		</div>

	</form>
</div>
