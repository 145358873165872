import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, map } from "rxjs";
import { CONSTANTS } from "src/constants";
import { environment } from "src/environments/environment";
import { BookSlotDto } from "../dtos/booking/book-slot.dto";
import { CreateEventDto } from "../dtos/booking/create-booking.dto";
import { EventMinimalDto } from "../dtos/event/event.minimal.dto";
import { HelperService } from "./helper.service";
import { EventDto } from "../dtos/event/event.dto";
import { EventBooking } from "../models/event-booking";


@Injectable({
	providedIn: 'root',
})

export class EventService {

	constructor(
		private http: HttpClient,
		private helperService: HelperService
	) {}


	getEventsByPeriod(startAt: Date, endAt: Date, regionId?: number): Observable<EventBooking[]> {
		const params = this.helperService.getObjectAsHttpParams({
			startAt: moment(startAt).format(CONSTANTS.DATE_TRANSACTION_FORMAT),
			endAt: moment(endAt).format(CONSTANTS.DATE_TRANSACTION_FORMAT),
			regionId
		});
		return this.http.get<EventMinimalDto[]>(`${environment.urlApi}/event/by-period`, { params })
			.pipe(
				map(dtoList => dtoList.map(dto => new EventBooking(dto)))
			)
	}

	getEvent(eventId: number) {
		return this.http.get<EventDto>(`${environment.urlApi}/event/${eventId}`)
	}

	updateEvent(dto: EventMinimalDto) {
		return this.http.put(`${environment.urlApi}/event/${dto.id}`, dto)
	}

	addEventToBooking(dto: CreateEventDto) {
		return this.http.post(`${environment.urlApi}/booking/${dto.bookingId}/add-event`, dto);
	}

	removeEvent(eventId: number) {
		return this.http.delete(`${environment.urlApi}/event/${eventId}`)
	}

	bookSlot(dto: BookSlotDto) {
		return this.http.put(
			`${environment.urlApi}/public/book-slot/${dto.slotId}`,
			dto
		)
	}

	cancelBooking(slotId: number) {
		return this.http.put(
			`${environment.urlApi}/reservation-slot/cancel-booking/${slotId}`,
			{}
		)
	}

}
