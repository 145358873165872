
<section>
	<div class="header">
		<h1 style="padding-left: 1rem">Prestations demandées</h1>
		<button
			class="button is-primary"
			(click)="formOpen = true"
			*ngIf="!formOpen"
			[disabled]="locked"
			[tooltipStr]="locked ? 'Le devis est accepté' : ''"
			>
			<mat-icon>add</mat-icon>
			<span>Ajouter une prestation</span>
		</button>
	</div>

	<form class="columns" [formGroup]="form" *ngIf="formOpen">
		<div class="column is-3">
			<mat-form-field>
				<mat-select formControlName="activityId" (selectionChange)="getActivityOffers()">
					<mat-option *ngFor="let activity of activityOptions" [value]="activity.id">{{activity.title}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="column is-3">
			<mat-form-field *ngIf="selectedActivity.offers.length > 0">
				<mat-select formControlName="offerId">
					<mat-option *ngFor="let offer of selectedActivity.offers" [value]="offer.id">{{offer.sessionConditions}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="column is-3">
			<mat-form-field subscriptSizing="dynamic" *ngIf="selectedActivity.activityType !== ACTIVITY_TYPES.FICHE">
				<mat-label>Date</mat-label>
				<input matInput required
					formControlName="date"
					[matDatepicker]="datepicker"
					placeholder="Date"
					[matDatepickerFilter]="weekendsDatesFilter"
				>
				<mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
				<mat-datepicker #datepicker></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="column is-flex is-justify-content-space-evenly is-align-items-center">
			<button class="button is-small is-light is-info" style="margin-bottom: 1rem;" [disabled]="!form.valid" (click)="addEvent()">
				<mat-icon>check</mat-icon>
			</button>
			<button class="button is-small is-light" style="margin-bottom: 1rem;" (click)="formOpen = false">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</form>


	<ng-container *ngIf="booking">
		<!-- EVENT LIST -->
		<div class="booking" *ngFor="let bookingEvent of booking.events let eventIndex = index;">
			<div class="columns is-vcentered">
				<div class="column is-3">
					<app-tile
						[animate]="false"
						[imageUrl]="bookingEvent.activity.image"
						[title]="bookingEvent.activity.title"
						[smallText]="true"
						aspectRatio="8/5">
					</app-tile>
				</div>
				<div class="column is-4">
					<mat-label>Format souhaité</mat-label>
					<p>
						<span>{{bookingEvent.offer.sessionConditions}}</span>
						<span *ngIf="bookingEvent.activity.activityType != ACTIVITY_TYPES.AUTRE && bookingEvent.activity.activityType != ACTIVITY_TYPES.FICHE"> ({{(bookingEvent.offer.sessionDuration / 60).toFixed(2).replace('.', 'h')}})</span>
					</p>
				</div>

				<div class="column is-2">
					<mat-label>Date souhaitée</mat-label>
					<p>{{helper.dateFr(bookingEvent.initialDate)}}</p>
				</div>
				<div class="column is-2">
					<ng-container *ngIf="showBookingButton(bookingEvent)">
						<mat-label>Réservations</mat-label>
						<p><a (click)="openBookingLinkModal(bookingEvent)">Lien</a></p>
					</ng-container>
				</div>
				<div class="column">
					<button
						class="button is-small is-inverted is-danger"
						(click)="deleteEvent(bookingEvent.id)"
						*ngIf="booking.events.length > 1"
						[disabled]="locked"
						[tooltipStr]="locked ? 'Le devis est accepté' : 'Supprimer la prestation'">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</section>
