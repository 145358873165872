<section class="container is-max-widescreen" *ngIf="event">

	<div class="columns is-centered" style="margin-top: 2rem">
		<div class="column is-4">

			<h1 style="text-align: center;">Réserver votre créneau</h1>

			<app-tile
				[animate]="false"
				[imageUrl]="event.activity.image"
				[title]="event.activity.title"
				[smallText]="false"
				aspectRatio="8/5"
				>
			</app-tile>

			<app-public-calendar mode="PUBLIC" [event]="event" (select)="onEventClick($event)"></app-public-calendar>
		</div>
	</div>


<!--
	<div class="columns is-centered">
		<div class="column is-4 box" id="left">
			<h4>{{event.clientName}}</h4>
			<div id="banner">
				<app-tile
					[animate]="false"
					[imageUrl]="event.activity.image"
					[title]="event.activity.title"
					[smallText]="false"
					aspectRatio="8/5">
				</app-tile>
			</div>
			

		<div class="column is-4">

		</div>
	</div> -->
</section>


