import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CalendarDateFormatter, CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ToastrModule } from "ngx-toastr";
import { NgxTooltipDirectivesModule } from "ngx-tooltip-directives";
import { JmaCalendarDateAdapter } from './adapters/jma-calendar-date.adapter';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from "./components/header/header.component";
import { MenuComponent } from "./components/header/menu/menu.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { PublicCalendarComponent } from "./components/public-calendar/public-calendar.component";
import { ResponsiveImageComponent } from "./components/responsive-image/responsive-image.component";
import { TileComponent } from "./components/tile/tile.component";
import { DynamicTableModule } from "./modules/dynamic-table/dynamic-table.module";
import { MaterialModule } from "./modules/material.module";
import { NotificationService } from "./services/notification.service";


@NgModule({
	declarations: [
		FooterComponent,
		HeaderComponent,
		TileComponent,
		ResponsiveImageComponent,
		MenuComponent,
		PublicCalendarComponent,
		LoaderComponent
	],
	imports: [
		CommonModule,
		ToastrModule.forRoot(),
		NgxTooltipDirectivesModule.forRoot({
			borderColor: 'none',
			animationDuration: 100
		}),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		MaterialModule,
		ReactiveFormsModule,
		DynamicTableModule,
		FormsModule,
		RouterModule,
	],
	exports: [
		CommonModule,
		NgxTooltipDirectivesModule,
		ToastrModule,
		HeaderComponent,
		FooterComponent,
		HttpClientModule,
		RouterModule,
		MaterialModule,
		ReactiveFormsModule,
		FormsModule,
		TileComponent,
		ResponsiveImageComponent,
		PublicCalendarComponent,
		LoaderComponent,
		DynamicTableModule
	],
	providers: [
		{ provide: CalendarDateFormatter, useClass: JmaCalendarDateAdapter },
		NotificationService
	]
})

export class SharedModule { }
